// Component Imports
import SiteContentInterface from "../../utils/structure/SiteContentInterface";
import ContentBlockModel from "../../utils/structure/ContentBlock/ContentBlockModel";
import Card from "../../components/card/Card";
import OpeningHours from "../../components/opening-hours/OpeningHoursElement";
import Gallery from "../../components/gallery/Gallery";
import Location from "../../components/location/Location";

// Import Text Content.
import sampleSection from "./text/sampleSection.md";

// Media Imports
import panoImage from "../images/pano.jpeg";

import gallery1 from "../images/1-r.jpeg";
import gallery2 from "../images/2-r.jpeg";
import gallery3 from "../images/3-r.jpeg";
import gallery4 from "../images/4-r.jpeg";
import gallery5 from "../images/5-r.jpeg";

import mapImage from "../images/location_map.png";

// Import Font Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone, faUser, faTimes, faInfo, faMapMarkedAlt, faCheck, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, } from '@fortawesome/free-regular-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import TextSection from "../../components/text-section/TextSection";
import DisplayableElement from "../../utils/structure/DisplayableElement";
import ContactLibrary from "../../components/common-contact/ContactLibrary";
import ContactType from "../../components/common-contact/ContactType";
import ContactEntry from "../../components/common-contact/ContactEntry";
import BlockTheme from "../../utils/structure/ContentBlock/BlockTheme";
import ContactForm from "../../components/contact-form/ContactForm";
import { useRef } from "react";


class SiteContent extends SiteContentInterface {
    constructor () {
        super();
        
        this.registerIconLibrary();
        this.populateApi();
        this.populateContact();
        this.populateHeader();
        this.populateFooter();
        this.addCustomContent();
    }

    // ==========================================================================================
    // Custom Content Content.
    // ==========================================================================================

    private addCustomContent = (): void => {

        const myRef = useRef(null);

        this.addHeroBlock(myRef);
        this.addBlockWithFeatures();
        this.addBlockWithCards();
        this.addBlockWithContactAndLocation(myRef);
        this.addBlockGallery();
    }

    private populateHeader = (): void => {
        this.setHeaderModel({
            title: "Charm Traditional Thai Massage",
            subtitle: "Premium, Professional & Traditional Massages",
            contactTypesToShow: [ContactType.PHONE, ContactType.EMAIL],
            theme: BlockTheme.FEATURE,
            blocks: []
        })
    }

    private populateFooter = (): void => {
        this.setFooterModel({
            height: 32,
            contactModel: this.getContactModel(),
            contactTextToShow: [ContactType.PHONE, ContactType.EMAIL],
            contactIconsToShow: [ContactType.FACEBOOK],
            theme: BlockTheme.INVERTED
        })
    }
    
    private addHeroBlock = (ref?: any): void => {
        const textElement = new TextSection({body: "Loading", markdownPath: sampleSection, centered: true, minHeight: 300, 
        callToActionLabel: "Contact Us & Book Now!", callToActionLink: "#", callToActionRef: ref});
        const blockModel = this.addElementsAsNewBlock(textElement);

        blockModel
            .setImagePositionX("80%")
            .setBackgroundImage(panoImage)
            .setDarkenPercent(0.3)
            .setTheme(BlockTheme.INVERTED);
    }

    private withPrice(body: string, duration: string, price: string) {
        const priceTag = `\n\n###### **${price}** — ${duration}`;
        return body + priceTag;
    }

    private newProductCard(title: string, body: string, image: string, prices: Array<Array<string>>) {
        body += "\n\n --- \n\n";
        for (let i: number = 0; i < prices.length; i++) {
            body = this.withPrice(body, prices[i][0], prices[i][1]);
        }

        const card = new Card({
            title: title, 
            body: body,
            imagePath: image,
        });

        return card;
    }

    private addBlockWithCards = (): void => {

        const card1 = this.newProductCard(
            "Deep Tissue Massage",
            "Relieve deep muscle sores and aches with a massage that targets your inner muscle fibers.",
            gallery4,
            [["60 Mins", "$75"]]
        )

        const card2 = this.newProductCard(
            "Foot Massage",
            "If you've been on your feet all day, this is perfect for you! Unwind with a soothing and tension-relieving foot massage.",
            gallery1,
            [["30 Mins", "$45"]]
        )


        const card3 = this.newProductCard(
            "Face Massage",
            "Treat your facial muscles with a rejuvenating massage to promote blood flow, and help you look and feel better!",
            gallery3,
            [["30 Mins", "$40"]]
        )

        const openingHours = new OpeningHours({
            showPublicHolidayMessage: true,
            slots: [
                {day: "Monday-Sunday", detail: "8 AM to 8 PM", isOpen: true},
            ]
        });

        this.addElementsAsNewBlock(card1, card2, card3).setTheme(BlockTheme.BASIC);
        this.addElementsAsNewBlock(openingHours);

    }

    private addBlockWithContactAndLocation = (ref: any): void => {

        const contactElement = new ContactForm({title: "Contact Us", contactFormApi: this.getEndpoint("contact"), 
        requireName: true, requireEmail: true, requirePhone: true, requireNotes: true});
        
        const mapLink: string | undefined = this.getContactModel().contactMap.get(ContactType.ADDRESS)?.link;
        const locationElement = new Location({
            title: "Our Location",
            mapAddress: mapLink ? mapLink : "",
            contactTypesToShow: [ContactType.ADDRESS, ContactType.PHONE, ContactType.EMAIL], 
            contactModel: this.getContactModel(),
            mapImage: mapImage});
            
        this.createNextBlock(BlockTheme.BASIC, ref)
        .add(contactElement)
        .add(locationElement);
    }

    private addBlockWithFeatures = (): void => {
  
        const card1 = this.newProductCard(
            "Yoga Stretching & Massage",
            "A relaxing and invigorating mixture of yogic stretches and massages to relieve your body of tension.",
            gallery2,
            [["60 Mins", "$90"]]
        )

        const card2 = this.newProductCard(
            "Steam Herbs Massage",
            "A combination of steam and herbs are incorporated into your massage to assure an aromatic and soothing massage experience",
            gallery5,
            [["60 Min", "$80"]]
        )
        
        this.addElementsAsNewBlock(card1, card2);
    }

    private addBlockGallery = (): void => {
        const galleryElement = new Gallery({
            images: [gallery1, gallery2, gallery3],
            imageColMd: 3
        });

        // this.addElementsAsNewBlock(galleryElement).setTheme(BlockTheme.FEATURE);
    }

    // ==========================================================================================
    // Common Content.
    // ==========================================================================================

    private populateContact = (): void => {

        const contactMap: Map<ContactType, ContactEntry> = new Map<ContactType, ContactEntry>();
        contactMap.set(ContactType.EMAIL, {body: "charmtk@yahoo.com.au", link: "mailto: charmtk@yahoo.com.au"});
        contactMap.set(ContactType.PHONE, {body: "0418 719 338", link: "tel: 0418 719 338"});
        // contactMap.set(ContactType.FACEBOOK, {body: "Facebook Address", link: "https://www.facebook.com"});
        contactMap.set(ContactType.ADDRESS, {
            body: "17 Merrifield Circle, Leeming <br> WA 6149", 
            link: "17 Merrifield Circle, Leeming, WA 6149"});

        this.setContactModel({
            name: "Charm Traditional Thai Massage",
            contactMap: contactMap
        });
    }

    private populateApi = (): void => {
        this.setApiEndpoint("https://apix.charmthaimassage.com.au/");
        this.setGoogleMapApiKey(undefined);
    }

    private registerIconLibrary = (): void => {

        library.add(faPhone, faEnvelope, faFacebookSquare, faUser, faTimes, faMapMarkedAlt);
        library.add(faInfo);
        library.add(faCheck);
        library.add(faEllipsisH);

        // Set the icons that we need for our contact library.
        const contactLibrary: ContactLibrary = ContactLibrary.getInstance();
        contactLibrary.setLabelAndIcon(ContactType.NAME, "Name", faUser);
        contactLibrary.setLabelAndIcon(ContactType.ADDRESS, "Address", faMapMarkedAlt);
        contactLibrary.setLabelAndIcon(ContactType.PHONE, "Phone", faPhone);
        contactLibrary.setLabelAndIcon(ContactType.EMAIL, "Email", faEnvelope);
        contactLibrary.setLabelAndIcon(ContactType.FACEBOOK, "Facebook", faFacebookSquare);
    }

    // ==========================================================================================
    // Utility.
    // ==========================================================================================

    private createNextBlock = (theme?: BlockTheme, ref?: any): ContentBlockModel => {
        const block = new ContentBlockModel(theme);
        block.ref = ref;
        this.getIndexPage().add(block);
        return block;
    }

    private addElementsAsNewBlock = (...elements: DisplayableElement[]): ContentBlockModel => {
        const block = this.createNextBlock();
        elements.forEach(e => block.add(e));
        return block;
    }
}

export default SiteContent

